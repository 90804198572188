import React, { useContext, useEffect, useRef } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  FormDataContext,
  BusinessDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";
import { useReactToPrint } from "react-to-print";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);

  document.title = "Data Servers-DataNow";

  const getBusinessName = (businessId) => {
    var businessName = "";
    businessData.businesses.map((business) => {
      if (business.business_id == businessId) businessName = business.name;
    });
    return businessName;
  };

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  useEffect(() => {
    if (!tempData.dataServersLoaded) {
      fetchDataPlans();
      tempDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "dataServersLoaded", value: true },
      });
    }
  }, []);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle:
      "Data Server Stat-" +
      new Date()
        .toLocaleString()
        .slice(0, 17)
        .replaceAll("/", "-")
        .replaceAll(",", "")
        .replaceAll(":", "-"),
  });

  const filterBusiness = (e) => {
    var dataServer = tempData.dataServersMaster;
    if (e.target.value)
      dataServer = dataServer.filter(
        (dataServer) => dataServer.business_id === e.target.value
      );

    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "dataServers", value: dataServer },
    });
  };

  const DataServer = (dataServer) => {
    return (
      <React.Fragment>
        <tr>
          <td>
            {(new Date() - new Date(dataServer.updated_at)) / 60000 < 30 ? (
              <label className="badge   badge-success  "> Logged In</label>
            ) : (new Date() - new Date(dataServer.updated_at)) / 60000 < 60 ? (
              <label className="badge   badge-warning  "> Timed Out</label>
            ) : (
              <label className="badge   badge-danger  ">Logged Out</label>
            )}
          </td>

          <td>
            {dataServer.data_sent_today_status == 0 ? (
              <label className="badge  badge-success small">All Sent</label>
            ) : (
              <label className="badge  badge-primary small">Active</label>
            )}
          </td>
          <td>
            {dataServer.data_sent_today == 5120 ? (
              <label className="badge  badge-success small">5GB Sent</label>
            ) : dataServer.data_sent_today > 0 ? (
              <label className="badge  badge-primary small">
                {dataServer.data_sent_today / 1024}GB Sent
              </label>
            ) : (
              dataServer.data_sent_today == 0 && (
                <label className="badge  badge-danger small">0GB Sent</label>
              )
            )}
          </td>

          <td>
            {dataServer.data_beneficiary_sent_today == 5120 ? (
              <label className="badge  badge-success small">5GB Sent</label>
            ) : dataServer.data_beneficiary_sent_today > 0 ? (
              <label className="badge  badge-primary small">
                {dataServer.data_beneficiary_sent_today / 1024}GB Sent
              </label>
            ) : (
              dataServer.data_beneficiary_sent_today == 0 && (
                <label className="badge  badge-danger small">0GB Sent</label>
              )
            )}
          </td>

          <td>{dataServer.network}</td>
          <td>{dataServer.name}</td>
          <td>{dataServer.phone_number}</td>
          <td>₦{formatCurrency(dataServer.airtime_balance)}</td>
          <td>{formatCurrency(dataServer.data_balance)}MB</td>
          <td> {getBusinessName(dataServer.business_id)}</td>
          <td> {dataServer.id}</td>
        </tr>
      </React.Fragment>
    );
  };

  const fetchDataPlans = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving data servers...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      localStorage.getItem("apiURL") + "businesses_data_server",
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "dataServers", value: data.data },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "dataServersMaster", value: data.data },
          });
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center ">
          <Col xl="12" ml="12" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>Data Servers </b>
                  </p>
                  <hr className="border-primary" />
                </div>

                <Row>
                  <Col md="3" xs="6" className="form-group mb-2">
                    <select
                      className="form-control"
                      value={formData.business_id}
                      name="business_id"
                      onChange={(e) => {
                        formOnChange(e);
                        filterBusiness(e);
                      }}
                    >
                      <option key="-1" value="">
                        All Businesses
                      </option>
                      {businessData.businesses.map((business, index) => {
                        return (
                          <option key={index} value={business.business_id}>
                            {business.name}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <button className="btn btn-primary m-2" onClick={handlePrint}>
                    Download
                  </button>
                </Row>

                {tempData.dataServers && (
                  <React.Fragment>
                    <div className="table-rep-plugin m-2" ref={printRef}>
                      <p className="text-uppercase text-left mb-2 mt-3  ">
                        Showing {tempData.dataServers.length} Data Servers{" "}
                        <b>
                          {new Date()
                            .toLocaleString()
                            .slice(0, 17)
                            .replaceAll("/", "-")
                            .replaceAll(",", "")
                            .replaceAll(":", "-")}
                        </b>
                      </p>
                      <Table responsive striped hover>
                        <thead>
                          <tr>
                            <th data-priority="1">Login Status</th>
                            <th data-priority="1">Data Sent Today Status</th>
                            <th data-priority="1">Data Sent Today</th>
                            <th data-priority="1">USSD Data Sent Today</th>
                            <th data-priority="1">Network</th>
                            <th data-priority="1">Name</th>
                            <th data-priority="1">Phone Number</th>
                            <th data-priority="1">Airtime Balance</th>
                            <th data-priority="1">Data Balance</th>
                            <th data-priority="1">Business</th>
                            <th data-priority="1">ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tempData.dataServers.map((dataServer) => {
                            return DataServer(dataServer);
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);
